import {
  faPhone as freeFasFaPhone,
  faEnvelope as freeFasFaEnvelope,
  faLocationDot as freeFasFaLocationDot,
  faTimes as freeFasFaTimes,
  faBars as freeFasFaBars,
  faChevronRight as freeFasFaChevronRight,
  faChevronLeft as freeFasFaChevronLeft,
  faChevronDown as freeFasFaChevronDown,
  faStar as freeFasFaStar,
  faCheck as freeFasFaCheck,
  faHome as freeFasFaHome,
  faRotateLeft as freeFasFaRotateLeft,
  faArrowRightLong as freeFasFaArrowRightLong,
  faSliders as freeFasFaSliders,
  faMagnifyingGlass as freeFasFaMagnifyingGlass,
  faCircleNotch as freeFasFaCircleNotch,
  faHeart as freeFasFaHeart,
  faFile as freeFasFaFile,
  faClock as freeFasFaClock
} from '@fortawesome/free-solid-svg-icons'

export default {freeFasFaPhone, freeFasFaEnvelope, freeFasFaLocationDot, freeFasFaTimes, freeFasFaBars, freeFasFaChevronRight, freeFasFaChevronLeft, freeFasFaChevronDown, freeFasFaStar, freeFasFaCheck, freeFasFaHome, freeFasFaRotateLeft, freeFasFaArrowRightLong, freeFasFaSliders, freeFasFaMagnifyingGlass, freeFasFaCircleNotch, freeFasFaHeart, freeFasFaFile, freeFasFaClock}