import { default as _91_91slug_93_938eLHcWm56bMeta } from "/root/projects/los-web/pages/[[slug]].vue?macro=true";
import { default as accessoiresaT3VEcICSLMeta } from "/root/projects/los-web/pages/accessoires.vue?macro=true";
import { default as algemene_45voorwaardenwuBMQNYpMhMeta } from "/root/projects/los-web/pages/algemene-voorwaarden.vue?macro=true";
import { default as annuleringsbeleidv47OBJyFR6Meta } from "/root/projects/los-web/pages/annuleringsbeleid.vue?macro=true";
import { default as contactyVWnnpliXtMeta } from "/root/projects/los-web/pages/contact.vue?macro=true";
import { default as cookiesontLKuT2vHMeta } from "/root/projects/los-web/pages/cookies.vue?macro=true";
import { default as index7lmpLOLqsGMeta } from "/root/projects/los-web/pages/index.vue?macro=true";
import { default as inspiratiea0x9W6Qe77Meta } from "/root/projects/los-web/pages/inspiratie.vue?macro=true";
import { default as linksRHBox2XXaZMeta } from "/root/projects/los-web/pages/links.vue?macro=true";
import { default as indexvrq6AsMKxqMeta } from "/root/projects/los-web/pages/machines/index.vue?macro=true";
import { default as slugAIKFw68AXvMeta } from "/root/projects/los-web/pages/machines/slug.vue?macro=true";
import { default as machinesKDDkIT7uidMeta } from "/root/projects/los-web/pages/machines.vue?macro=true";
import { default as over_45onsh094RrFk6sMeta } from "/root/projects/los-web/pages/over-ons.vue?macro=true";
import { default as privacyverklaring1UVmkuG9zFMeta } from "/root/projects/los-web/pages/privacyverklaring.vue?macro=true";
import { default as reparatiesgr0JAwgR5aMeta } from "/root/projects/los-web/pages/reparaties.vue?macro=true";
import { default as _91slug_937pxUwlbqJNMeta } from "/root/projects/los-web/pages/v2/[slug].vue?macro=true";
import { default as inspiratie87Or9cpskQMeta } from "/root/projects/los-web/pages/v2/inspiratie.vue?macro=true";
import { default as index7opVNl7NhBMeta } from "/root/projects/los-web/pages/workshops/[slug]/index.vue?macro=true";
import { default as indexJLvH3Abck6Meta } from "/root/projects/los-web/pages/workshops/index.vue?macro=true";
import { default as workshopsXWQ6GYQQWtMeta } from "/root/projects/los-web/pages/workshops.vue?macro=true";
import { default as component_45stubnPqIF7Qt9hMeta } from "/root/projects/los-web/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnPqIF7Qt9h } from "/root/projects/los-web/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug?",
    component: () => import("/root/projects/los-web/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "accessoires",
    path: "/accessoires",
    component: () => import("/root/projects/los-web/pages/accessoires.vue").then(m => m.default || m)
  },
  {
    name: "algemene-voorwaarden",
    path: "/algemene-voorwaarden",
    component: () => import("/root/projects/los-web/pages/algemene-voorwaarden.vue").then(m => m.default || m)
  },
  {
    name: "annuleringsbeleid",
    path: "/annuleringsbeleid",
    component: () => import("/root/projects/los-web/pages/annuleringsbeleid.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/root/projects/los-web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/root/projects/los-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/projects/los-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inspiratie",
    path: "/inspiratie",
    component: () => import("/root/projects/los-web/pages/inspiratie.vue").then(m => m.default || m)
  },
  {
    name: "links",
    path: "/links",
    component: () => import("/root/projects/los-web/pages/links.vue").then(m => m.default || m)
  },
  {
    name: machinesKDDkIT7uidMeta?.name,
    path: "/machines",
    component: () => import("/root/projects/los-web/pages/machines.vue").then(m => m.default || m),
    children: [
  {
    name: "machines",
    path: "",
    component: () => import("/root/projects/los-web/pages/machines/index.vue").then(m => m.default || m)
  },
  {
    name: "machines-slug",
    path: "slug",
    component: () => import("/root/projects/los-web/pages/machines/slug.vue").then(m => m.default || m)
  },
  {
    name: "machines-type",
    path: "/machines/:type",
    component: () => import("/root/projects/los-web/pages/machines/index.vue").then(m => m.default || m)
  },
  {
    name: "machines-brand",
    path: "/machines/:type/:brand",
    component: () => import("/root/projects/los-web/pages/machines/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "over-ons",
    path: "/over-ons",
    component: () => import("/root/projects/los-web/pages/over-ons.vue").then(m => m.default || m)
  },
  {
    name: "privacyverklaring",
    path: "/privacyverklaring",
    component: () => import("/root/projects/los-web/pages/privacyverklaring.vue").then(m => m.default || m)
  },
  {
    name: "reparaties",
    path: "/reparaties",
    component: () => import("/root/projects/los-web/pages/reparaties.vue").then(m => m.default || m)
  },
  {
    name: "v2-slug",
    path: "/v2/:slug()",
    component: () => import("/root/projects/los-web/pages/v2/[slug].vue").then(m => m.default || m)
  },
  {
    name: "v2-inspiratie",
    path: "/v2/inspiratie",
    component: () => import("/root/projects/los-web/pages/v2/inspiratie.vue").then(m => m.default || m)
  },
  {
    name: workshopsXWQ6GYQQWtMeta?.name,
    path: "/workshops",
    component: () => import("/root/projects/los-web/pages/workshops.vue").then(m => m.default || m),
    children: [
  {
    name: "workshops-slug",
    path: ":slug()",
    component: () => import("/root/projects/los-web/pages/workshops/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "workshops",
    path: "",
    component: () => import("/root/projects/los-web/pages/workshops/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "machine-slug",
    path: "/machines/:type/:brand/:slug",
    component: () => import("/root/projects/los-web/pages/machines/slug.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnPqIF7Qt9hMeta?.name,
    path: "/winkel/team",
    component: component_45stubnPqIF7Qt9h
  },
  {
    name: component_45stubnPqIF7Qt9hMeta?.name,
    path: "/winkel",
    component: component_45stubnPqIF7Qt9h
  }
]