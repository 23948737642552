<template>
  <div
    class="grid min-h-screen grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr] text-los-darkest-gray"
  >
    <header
      class="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8"
    >
      <a href="/">
        <span class="sr-only">Los Naaimachines</span>
        <img
          class="h-24 w-auto sm:h-32"
          src="/img/Logo-Los.png"
          alt="Los Naaimachines Logo"
        />
      </a>
    </header>
    <main
      class="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8"
    >
      <div class="max-w-lg">
        <p class="text-base font-semibold leading-8 text-los-purple-darkest">
          Foutmelding - 404
        </p>
        <h1 class="mt-4 text-3xl font-light tracking-tight sm:text-5xl">
          Pagina niet gevonden
        </h1>
        <p class="mt-6 text-lg leading-7">
          Sorry, de pagina die je zoekt bestaat niet.
        </p>
        <div class="mt-10">
          <a
            href="/"
            class="text-sm font-semibold leading-7 text-los-purple-darkest"
            ><span aria-hidden="true">&larr;</span> Terug naar home</a
          >
        </div>
      </div>
    </main>

    <div
      class="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block border-l-2 border-dashed border-los-pink-base"
    >
      <img
        src="/deco/los_voorgevel_lg.webp"
        alt="Voorgevel van de winkel"
        class="absolute inset-0 h-full w-full object-cover"
      />
    </div>
  </div>
</template>

<script setup>
usePageSeo({
  metaTitle: 'Foutmelding 404 - Los Naaimachines',
});
</script>

<style lang="scss" scoped></style>
