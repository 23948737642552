export default function (seo = { isArticle: false }) {
  const seoImg = seo.metaImage?.formats.small ?? {};
  const title =
    seo.metaTitle ?? 'Los Naaimachines | Alles voor naailiefhebbers';
  const description =
    seo.metaDescription ??
    'Ontdek ons assortiment aan kwalitatieve naaimachines, onderdelen, en accessoires. Bezoek Los Naaimachines in Roelofarendsveen.';

  useSeoMeta({
    title,
    description,
    ogTitle: title,
    ogDescription: description,
    ogImageUrl: seoImg.url,
    ogImageType: seoImg.mime,
    ogImageWidth: seoImg.width,
    ogImageHeight: seoImg.height,
    ogImageAlt: seo.metaImage?.alternativeText,
    ogLocale: 'nl_NL',
    ogType: seo.isArticle ? 'article' : 'website',
    ogSiteName: 'Los Naaimachines',
    ogUrl: useRequestURL().href,

    author: 'https://www.facebook.com/losnaaimachines/',
    publisher: 'https://www.facebook.com/losnaaimachines/',
    ...(function () {
      if (!seo.isArticle) {
        return {};
      }
      return {
        articlePublisher: 'https://www.facebook.com/losnaaimachines/',
        articlePublishedTime: seo.articlePublishedTime,
        articleModifiedTime:
          seo.articleModifiedTime ?? seo.articlePublishedTime,
        articleSection: seo.articleSection,
      };
    })(),
  });
}
